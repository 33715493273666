import type { StoreOptions } from 'vuex'

const state = {
  schedule: [] as JPDay[]
}

type StateDef = typeof state

export default {
  namespaced: true,
  state,
  getters: {
    isActive: state => (time: string, day: string) => {
      const item = state.schedule.find(item => item.day == day && item.post_time == time)
      return item && item.count > 0
    }
  },
  mutations: {
    clearAll(s: StateDef) {
      s.schedule = s.schedule.map(el => ({ ...el, count: 0 }))
    },
    seed(s: StateDef, schedule) {
      s.schedule = schedule
    },
    decrement(s: StateDef, payload: JPCellTarget) {
      const { time, day } = payload
      const existing = s.schedule.find(
        el => el.day == day && el.post_time == time
      )
      if (existing) {
        existing.count -= 1
        s.schedule = [...s.schedule]
      }
    },
    increment(s: StateDef, payload: JPCellTarget) {
      const { time, day } = payload
      const existing = s.schedule.find(
        el => el.day == day && el.post_time == time
      )
      if (!existing) {
        s.schedule = s.schedule.concat({
          post_time: time,
          day,
          count: 1,
        })
      } else {
        existing.count += 1
      }
    },
  }
} as StoreOptions<StateDef>
