import { Controller } from "stimulus"
import qs from 'qs'
import "core-js/es/object/from-entries";
import flatFormData from 'utils/flatFormData'

import _debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = [ "preview"]

  declare readonly previewTarget: Element
  debouncedUpdate: Function;

  connect() {
    this.debouncedUpdate = _debounce(async () => {
      const relevant = flatFormData(this.element)
      const queryString = qs.stringify(Object.fromEntries(relevant))

      const r = await fetch(`/js/jobs_preview?${queryString}`)
      const text = await r.text()
      this.previewTarget.innerHTML = text
    }, 300)
    this.debouncedUpdate()
  }

  async update() {
    this.debouncedUpdate()
  }
}
