import BSN from 'bootstrap.native'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content', 'checkbox']
  declare readonly contentTarget: Element
  declare readonly checkboxTarget: HTMLInputElement

  target: Element
  bsn: any

  connect() {
    this.target = this.element.querySelector('.card-body')
    this.bsn = new BSN.Collapse(document.createElement('div'), { target: this.target })
    this.update()
  }

  update() {
    if (this.checkboxTarget.checked) {
      this.bsn.show()
    } else {
      this.bsn.hide()
    }
  }
}
