import Vue from 'vue'
import VueI18n from 'vue-i18n'
import allI18n from './translations.js.erb'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: window.Locale,
  fallbackLocale: 'de',
})

declare global {
  interface Window { Locale: string }
}


const rawData = allI18n[window.Locale]

i18n.setLocaleMessage(window.Locale, rawData)

export default i18n
