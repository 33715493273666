import Vuex from "vuex"
import Vue from "vue"

import scheduleStore from './components/scheduler/scheduleStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    schedule: scheduleStore
  }
})

export default store

