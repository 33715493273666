import tippy from 'tippy.js';
import type { Props, Instance } from 'tippy.js'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/shift-away.css';

export default (element: Element, content: string, opts: Partial<Props> = {}): Instance<Props> => {
  const popup = tippy(element, {
    ...opts,
    appendTo: () => document.body,
    content,
    theme: 'material',
    animation: "shift-away",
    allowHTML: true,
    showOnCreate: true,
    interactive: true,
    trigger: "manual",
  });
  return popup
}
