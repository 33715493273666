// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  // Write Documentation:
  // This attaches to a checkbox and shows/hides references collapse element
  // example:
  // <div data-controller="collapse-check">
  //   <input type="checkbox" data-collapse-check-target="check">
  //   <div data-collapse-check-target="show">
  //    ...
  //   </div>
  //   <div data-collapse-check-target="hide">
  //   ...
  //   </div>
  static targets = ["check", "show", "hide"]
  static values = {
    trigger: String
  }

  declare showTarget: HTMLElement | undefined
  declare hideTarget: HTMLElement | undefined
  declare checkTarget: HTMLInputElement
  declare checkTargets: HTMLInputElement[]
  declare hideTargets: HTMLElement[]
  declare showTargets: HTMLElement[]
  declare hasHideTarget: boolean
  declare hasCollapseTarget: boolean
  declare triggerValue: string


  connect() {
    const that = this
    this.showTargets.forEach((element) => {
      element.classList.add("collapse")
    })
    this.hideTargets.forEach((element) => {
      element.classList.add("collapse")
    })
    $(this.checkTargets)
      .on('change', function(ev) {
        const value = $(this).val()
        let isChecked = $(this).is(":checked")
        const showValue = !that.triggerValue || that.triggerValue == value
        if (ev.target.tagName == "SELECT") {
          isChecked = true
        }
        if (showValue && isChecked) {
          that.show()
        } else {
          that.hide()
        }
      })
      .trigger('change')
  }

  show() {
    this.showTargets.forEach((element) => {
      element.classList.add("show")
    })
    this.hideTargets.forEach((element) => {
      element.classList.remove("show")
    })
  }
  hide() {
    this.showTargets.forEach((element) => {
      element.classList.remove("show")
    })
    this.hideTargets.forEach((element) => {
      element.classList.add("show")
    })
  }
}
