import 'core-js/modules/es.promise';

import Vue from 'vue'

import axios from 'axios';

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;
axios.defaults.baseURL = '/';
axios.defaults.withCredentials = false;
axios.defaults.headers.common['Accept'] = 'application/json'

const updateTokenFromDom = () => {
  const tokenDom = document.getElementsByName('csrf-token')[0]
  if (tokenDom) {
    const token = tokenDom.getAttribute('content')
    axios.defaults.headers.common['X-CSRF-Token'] = token
  }
}

document.addEventListener("DOMContentLoaded", updateTokenFromDom)
document.addEventListener("turbo:load", updateTokenFromDom)
