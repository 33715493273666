import Vue from 'vue'
import { VBTooltip } from "bootstrap-vue"
Vue.directive('b-tooltip', VBTooltip);

import store from "./store"

import AutomountAll from "utils/automount-mutation"

AutomountAll({
  "jp-schedule": {
    component: () => import("components/scheduler/JpScheduler"),
    extra: { store },
  },
  "subscriber-table": {
    component: () => import("components/subscriber_table/SubscriberTable")
  },
  "subscribe-email-list": {
    component: () => import("components/SubscriberEmailList")
  },
  "editor-with-placeholders": {
    component: () => import("components/editor/EditorWithPlaceholders")
  },
  "subscriber-with-survey-form": {
    component: () => import("components/subscriber_form/SubscriberWithSurveyForm")
  },
  "subscriber-form": {
    component: () => import("components/subscriber_form/SubscriberForm")
  },
  "survey-form": {
    component: () => import("components/subscriber_form/SurveyForm")
  },
  'push-jobs': {
    component: () => import("components/push_job/PushJob")
  },
  'feed-selector': {
    component: () => import("components/feed_selector/FeedSelector")
  },
  'highcharts': {
    component: () => import("components/HighchartsWrapper")
  },
  "job-row-with-share": {
    component: () => import("components/push_job/JobRowWithShare.vue")
  },
  "survey-participant-table": {
    component: () => import("components/SurveyParticipantTable.vue")
  }
})


