import ahoy from "ahoy.js";

window.ahoy = ahoy

declare global {
  interface Window { ahoy: typeof ahoy; }
}

ahoy.configure({
  cookies: false,
  visitsUrl: "/ahoyyy/visits",
  eventsUrl: "/ahoyyy/events",
  useBeacon: false,
})

export default ahoy
