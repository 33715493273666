import Vue from "vue";
import datasetToProps from "./datasetToProps";

import i18n from 'utils/i18n'

const mount = (node, component, extra = {}) => {
  const props = datasetToProps(node);
  new Vue({
    ...extra,
    el: node,
    i18n,
    render: h => h(component, { props })
  });
};

const scanAndMount = (parent, config) => {
  if (!parent.querySelectorAll) return
  Object.keys(config).forEach(name => {
    const {component, extra} = config[name];
    Array.from(parent.querySelectorAll(name)).forEach(node => {
      mount(node, component, extra);
    });
  });
}

const AutomountAll = config => {
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      mutation.addedNodes.forEach(node => {
        scanAndMount(node, config)
      });
    });
  });
  observer.observe(document, {
    subtree: true,
    childList: true
  });
  document.addEventListener("DOMContentLoaded", () => {
    scanAndMount(document, config)
  });
};

export default AutomountAll;
