const rbrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/

// aus jquery data
const parseData = (data) => {
  if (data === "true") {
    return true
  }
  if (data === "false") {
    return false
  }
  if (data === "null") {
    return null
  }
  // Only convert to a number if it doesn't change the string
  if (data === +data + "") {
    return +data
  }
  if (rbrace.test(data)) {
    return JSON.parse(data)
  }
  return data
}


const datasetToProps = (el) => {
  const rawData = Object.assign({}, el.dataset)
  const data = {}
  Object.keys(rawData).forEach(key => data[key] = parseData(rawData[key]))
  return data
}

export { datasetToProps, parseData }
export default datasetToProps
